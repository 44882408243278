import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';

import SupportPageTemplate from '../../components/SupportPageTemplate';
import { CommonContext } from '../../context';

export const query = graphql`
  query ($category: String!) {
    categories: allWpFaq {
      group(field: faq___category) {
        fieldValue
      }
    }
    allWpFaq(filter: { faq: { category: { eq: $category } } }) {
      nodes {
        id
        title
        content
        slug
        faq {
          category
        }
      }
    }
  }
`;

function SupportTopics({ data, location, pageContext }) {
  const questions = data.allWpFaq.nodes;
  const categoryData = data.categories.group.find(
    (item) =>
      item.fieldValue.toLowerCase() === pageContext.category.toLowerCase(),
  );
  const allCategoryData = data.categories.group;
  const breadcrumbLinks = [
    { label: 'Support', url: '/support' },
    { label: categoryData.fieldValue },
  ];
  const title = categoryData.fieldValue;
  const links = [];
  allCategoryData.forEach((item) => {
    if (pageContext.category.toLowerCase() !== item.fieldValue.toLowerCase()) {
      links.push({
        label: item.fieldValue,
        url: '/support/' + item.fieldValue.toLowerCase() + '/',
      });
    }
  });
  const additionaliLinkObject = {
    title: 'Browse by topic',
    links: links,
  };

  return (
    <CommonContext.Provider value={{ location }}>
      <SupportPageTemplate
        title={title}
        additionaliLinkObject={additionaliLinkObject}
        breadcrumbLinks={breadcrumbLinks}
        location={location}
      >
        {questions.map((item) => (
          <div className="my-4 text-accent-2" key={item.title}>
            <Link
              className="link-underline-accent"
              to={`/support/${item.slug}`}
            >
              {item.title}
            </Link>
          </div>
        ))}
      </SupportPageTemplate>
    </CommonContext.Provider>
  );
}

SupportTopics.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default SupportTopics;
